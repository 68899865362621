<template>
    <div>
        <Header />
         <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/esd/helground.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Anti Static Heel Grounders
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/wriststraps">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/esd/wriststrap.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Antistatic Wrist Straps 
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/esd/groundcord.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               Earth Grounding Cords
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/mats_and_kits">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/esd/mats.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                ESD Mats And Kits
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="esdclothing()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:60%;margin:auto"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/esd/clothing.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               ESD Clothing
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/esd/gants.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                ESD Gloves
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/brusheswipes">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/esd/brushesss.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                ESD Brush & Wipes
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/esd/brush.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                ESD Hand Tools 
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/chairs">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:40%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/esd/chair.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                ESD Chairs
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/stationarylamps">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:70%; max-width:40%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/esd/statio.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               ESD Stationary & Magnifier Lamps
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/esd/racks.jpg"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                ESD PCB Holders & Racks
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/esd/tester.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Tester & Tapes & Others
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/wire">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/esd/cage.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               Wire Sheving & Cage 
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
       
      </div>
    </div>
        <Footer />
    </div>
</template>
<script>

import Header from '../../components/Header.vue';

import Footer from '../../components/Footer.vue';

/**
 * Dashboard component
 */
export default {
  components: {
   
    Header,
  
    Footer
    
   
  },
  methods: {
      esdclothing(){
        window.location.href = "https://www.ebruzen.com.tr/en/antistatic-esd-labcoat-smock-lc05/27/3/5";
  
    },
  },
  
};
</script>